import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ServiceAPI from "../service/ServiceAPI";
import Constants from "../utils/Constants";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FollowMarker from "./FollowMarker";

function MarkerSelect() {
  const params = useParams();

  const [event, setEvent] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    ServiceAPI.Events().getEvent(params.eventId).then(
      (data) => { setEvent(data); }
    ) 
  }, []);

  function render() {
    if (event == null) {
      return <h1>Loading the Event Details</h1>;
    }

    if (marker != null) {
      return <FollowMarker marker={ marker }/>
    }

    return (
      <div>
        <h1>Select a tag to track.</h1>

        <div>
          {
            event.markers.map((i) => {
              return renderMarkerCard(i);
            })

          }
        </div>
      </div>
    )
  }

  function renderMarkerCard(marker) {
    const card = (
      <Card variant="outlined" sx={{mb: '1em'}}>
        <React.Fragment>
          <CardContent>
            <Typography variant="h5" component="div">
              { marker.description }
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              { marker.region }
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" size="large" onClick={ () => { setMarker(marker); }}>Follow Tag</Button>
          </CardActions>
        </React.Fragment>
      </Card>
    );

    return card;
  }

  return render();
}

export default MarkerSelect;