import Constants from '../utils/Constants';
import ServiceBase from './ServiceBase';

const EVENT = 'event/';

export default class EventsService extends ServiceBase {

    getEventListing = () => {
      let url = this.getServiceUrl(EVENT);

      let packet = this.buildRequestPacket(Constants.Http.Method.GET);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        return json;
      });
    }

    getEvent = (id) => {
      let url = this.getServiceUrl(EVENT + "?" + Constants.Http.Param.Id + "=" + id);

      let packet = this.buildRequestPacket(Constants.Http.Method.GET);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        console.log(json);
        return json;
      });
    }

}