import * as React from 'react';


function FollowMarker(params) {
  
  function render() {
    return (
      <>
        <h1>{ params.marker.description}</h1>

        <div>
          { params.marker.region}
        </div>

        <div style={{height: '70%'}}>
          <iframe src={ params.marker.glympseTrackingUrl } width="99%" height="100%" style={{overflow: 'none'}}></iframe>
        </div>
      </>
    )
  }

  
  return render();
}

export default FollowMarker;