
const API_BASE_URL = 'https://api.erma-app.com';

module.exports = Object.freeze(
  {
    FeatureToggle: {
      CompleteInventoryEnabled: false
    },

    ServiceAPI: {
      baseUrl: `${API_BASE_URL}/api/geotag/`,
      authenticateUrl: `${API_BASE_URL}/api/authenticate/`
    },

    Http: {
      Method: {
        GET: 'GET',
        POST: 'POST',
        PUT: 'PUT',
        DELETE: 'DELETE'
      },
      
      Status: {
        OK: 200,
        INTERNAL_ERROR: 500
      },

      Param: {
        Id: "id"
      }
    },
    
  
    
    RoutePath: {
      Home: "/",
      Events: "/events",
      Join: (id) => { return `/join/${id}`},
      Follow: (id) => { return `/follow/${id}`}
    }

  }
);
