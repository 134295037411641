
import Constants from "../utils/Constants";

const baseUrl = Constants.ServiceAPI.baseUrl;

export default class ServiceBase {

  /**
   * 
   * @param {*} endpoint 
   * @returns 
   */
  getServiceUrl = (endpoint) => {
    return `${baseUrl}${endpoint}`
  }

  /**
   * Builds the packet object for the fetch request
   * @param {Constants.Http.Method} method 
   * @param {Object} body 
   * @param {Object} headers 
   */
  buildRequestPacket = (method, body = null, headers = {}) => {
    let packet = {
      method: method,
      cors: true,
      headers: this.getRequestHeaders(headers),
    };

    if (body !== null) {
      // Need to check for the toJson function, as this function is used for 
      // the custom model objects to convert the object into JSON. Using 
      // JSON.stringify on custom model objects will result in API errors.
      packet.body = body.toJson ? body.toJson() : JSON.stringify(body)
    }

    return packet;
  }

  /**
   * 
   * @returns 
   */
  getRequestHeaders = (extraHeaders = {}) => {
    return {
      ...extraHeaders,
      'Content-Type': 'application/json',
      //'Authorization': `Bearer ${ getAuth()?.jwt }`
    }
  }

  /**
   * 
   * @param {*} response 
   * @returns 
   */
  convertResponseToJson = (response) => {
      // TODO: add error handling in case the call fails.
      //console.log("ConvertResponseToJson:", response);

      let responseOutcome = null;

      switch (response.status) {
        
        case 200:
          responseOutcome = response.json();
          break;

        default:
          responseOutcome = {
            error: true,
            status: response.status,
            message: response.statusText
          };
      }
      
      return responseOutcome;
  }
}