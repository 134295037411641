import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import Constants from '../../utils/Constants';

const Menu = (props) => {
  let nav = useNavigate();

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: '1em'}}>
          <MenuIcon onClick={ () => { nav(Constants.RoutePath.Home) } }/>
        </IconButton>

        <Typography variant="h6" >
          ERMA GeoTag
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Menu;
