import EventsService from './EventsService';

class ServiceAPI {
  
  Events = () => {
    return new EventsService();
  }

}

export default new ServiceAPI();