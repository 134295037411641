import { Routes, Route } from "react-router-dom";
import Constants from "../../utils/Constants";
import Event from '../Event';
import FollowMarker from "../FollowMarker";
import MarkerSelect from "../MarkerSelect";

function RouteController() {
  return(
    <Routes>
      <Route path={ Constants.RoutePath.Home } exact Component={ () => <Event /> } />
      <Route path={ Constants.RoutePath.Events } exact Component={ () => <Event /> } />
      <Route path={ Constants.RoutePath.Join(":eventId") } exact Component={ () => <MarkerSelect /> } />
      <Route path={ Constants.RoutePath.Follow(":tagUrl") } exact Component={ () => <FollowMarker /> } />
    </Routes>
  );
}

export default RouteController;