import Menu from "./Menu";
import RouteController from "./RouteController";

function Layout() {

  function render() {
    return (
      <div id="layout">
        <Menu />        
        
        <div id="app-body">
          <RouteController />
        </div>
      </div>
    );
  };

  return render();
}

export default Layout;